import React, { Component } from 'react';
import { Application } from './core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import { userSignedIn } from './actions/auth';
import { hideMessageBar } from './actions/app';
import detectBrowserLanguage from 'detect-browser-language';
import { getClientDetails } from './actions/client';
import { getWhiteLabelsAction } from './actions/white-label';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'

// Import components
import AdminPanel from './containers/layouts/admin-panel';
import AccessLayout from './containers/layouts/access';
import Home from './containers/home';
import Impersonate from './containers/impersonate';
import MsgBar from './components/application/msg-bar';
import Receipts from './containers/receipts';
import Settings from './containers/settings';
import Statements from './containers/statements';
import Documents from './containers/documents';
import StatementsAndDocuments from './containers/statements-and-documents';
import Schedule from './containers/schedule';
import SignIn from './containers/signin';
import SignUp from './containers/signup';
import OtpForm from './components/auth/otp-form';
import WSLoader from './components/application/whole-screen-loader';
import Servicing from './containers/servicing';
import { isEmpty } from './helpers/app';

class App extends Component {
  /**
   * Fetch the initial data.
   */

  constructor (props) {
    super(props)
    if (isEmpty(localStorage.getItem('language'))) {
        localStorage.setItem('language', 'en');
    }

    this.state = {
      appLanguage: 'en'
    }
  }
  componentDidMount() {
    const accessToken = Application.getAccessToken();
    const isValidToken = accessToken && accessToken.length > 0;
    if (isValidToken) {
      this.props.userSignedIn(true);
      return ;
    }
    if (localStorage.getItem('language')) {
      var language = localStorage.getItem('language');
    } else {
      var language = detectBrowserLanguage().split('-')[0];
    }
    localStorage.setItem('language', language);
    this.setState({appLanguage : language});
    this.props.userSignedIn(false);
    
    const lang = localStorage.getItem('language');
    import('date-fns/esm/locale').then((response) => {
    const allLangs = Object.keys(response);
        allLangs.forEach((currentLang) => {
            // Registering all the languages for datepicker
            registerLocale(currentLang, response[currentLang]); 
        });
    });
  }

  handleLanguageChange = (ev) => {
    this.setState({appLanguage : ev.target.value});
    localStorage.setItem('language', ev.target.value);
    this.props.getWhiteLabelsAction(ev.target.value);
    this.props.getClientDetails(ev.target.value);
    setDefaultLocale(ev.target.value);
  }

  /**
   * Handle the event when msg bar is dismissed.
   */
  handleDismissMsgBar = () => {
    this.props.hideMessageBar()
  }

  /**
   * Render the component view.
   */
  render() {
    const { loggedIn, canShowOTP } = this.props;

    if (loggedIn === null) {
      return null;
    }

    return (
      <div className="App">
        {this.props.loading && <WSLoader />}

        <MsgBar
          text={this.props.message.text}
          status={this.props.message.status}
          onDismiss={this.handleDismissMsgBar} />

        <Switch>
          <Route exact path="/login" render={() => 
            loggedIn ? (
              <Redirect to="/home" />
            ) : (
              <AccessLayout language={this.state.appLanguage}>
                <SignIn whiteLabel={this.props.whiteLabel} language={this.state.appLanguage} onLanguageChange={this.handleLanguageChange}/>
              </AccessLayout>
            )
          } />

          <Route exact path="/signup" render={() => 
            loggedIn ? (
              <Redirect to="/home" />
            ) : (
              <AccessLayout language={this.state.appLanguage} type="signup">
                <SignUp whiteLabel={this.props.whiteLabel} language={this.state.appLanguage}/>
              </AccessLayout>
            )
          } />

          <Route exact path="/verifyOTP" render={() => {
            if (loggedIn) {
              return <Redirect to="/home" />
            }

            if (!canShowOTP) {
              return <Redirect to="/signup" />
            }

            return (
              <AccessLayout language={this.state.appLanguage}>
                <OtpForm 
                    validationCodeSeconds={this.props.client.validationCodeSeconds} 
                    validationCodeCallOption={this.props.client.validationCodeCallOption} 
                />
              </AccessLayout>
            )
          }} />

          <AdminLayout exact path="/home" loggedIn={loggedIn} component={Home} />
          <AdminLayout exact path="/servicing" loggedIn={loggedIn} component={Servicing} />
          <AdminLayout exact path="/schedule-payment" loggedIn={loggedIn} component={Schedule} />
          <AdminLayout exact path="/receipts" loggedIn={loggedIn} component={Receipts} />
          <AdminLayout path="/statements" loggedIn={loggedIn} component={Statements} />
          <AdminLayout path="/statements-and-documents" loggedIn={loggedIn} component={StatementsAndDocuments} />
          <AdminLayout path="/documents" loggedIn={loggedIn} component={Documents} />
          <AdminLayout path="/settings" loggedIn={loggedIn} component={Settings} />
          <Route exact path="/redirect" render={() => <Impersonate />} />

          <Route exact path="/" render={() => 
            loggedIn ? (
              <Redirect to="/home" />
            ) : (
              <Redirect to="/login" />
            )
          } />
          
          {/*<Route render={() => {
            return <Redirect to="/" />
          }} />*/}

        </Switch>
      </div>
    );
  }
}

const AdminLayout = ({ loggedIn, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
      return (
        loggedIn === true
          ? <AdminPanel whiteLabel={props.whiteLabel}>
              <Component {...props} />
            </AdminPanel>
          : <Redirect to='/login' />
      );
    }} />
);

const mapStateToProps = state => {
  return {
    canShowOTP: state.auth.canShowOTP,
    loading: state.app.loading,
    loggedIn: state.auth.loggedIn,
    message: state.app.message,
    mState: state,
    whiteLabel: state.whiteLabel,
    client: state.client.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userSignedIn, hideMessageBar, getWhiteLabelsAction, getClientDetails
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
